<template>
	<div class="container page">
		<van-nav-bar :title="$t('home.select_language')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main-content">
			<div class="item van-hairline--bottom" @click="switchLanguage('en')">
				<div class="left">English</div>
				<div class="right">
					<van-icon name="arrow" />
				</div>
			</div>
			<div class="item van-hairline--bottom" @click="switchLanguage('ko')">
				<div class="left">한국인</div>
				<div class="right">
					<van-icon name="arrow" />
				</div>
			</div>
			<div class="item van-hairline--bottom" @click="switchLanguage('zh')">
				<div class="left">简体中文</div>
				<div class="right">
					<van-icon name="arrow" />
				</div>
			</div>
			<div class="item van-hairline--bottom" @click="switchLanguage('ja')">
				<div class="left">日本語</div>
				<div class="right">
					<van-icon name="arrow" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			currentLanguage: 'en' // 默认语言为英语
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		switchLanguage(lang) {
			this.currentLanguage = lang;
			this.$i18n.locale = lang; // 切换当前语言
			window.localStorage.setItem('selected_language', lang);
			this.$router.push({ path: '/Home' });
		}
	},

};
</script>

<style lang='less' scoped>
.container .main-content {
	padding: 0 20px;
	background-color: #fff;
}

.container .main-content .item {
	padding: 30px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 30px;}

</style>