<template>
	<div class="home-container">
		<van-nav-bar class="nav-bar newnavbar" :title="$t('home.title')">
			<template #left>
				<van-image src="img/kefu.png" @click="showServiceOnline()" style="width: 32px;" />
			</template>
			<template #middle>
				<span style="font-size: 16px; color: rgb(255, 255, 255); display: none;">【{{$t('login.title')}}】</span>
			</template>
			<template #title>
				<van-image class="logo" src="img/home/homenavlogo.png"
					style="height: 30px; margin: 10px 0px 0px;"></van-image>
			</template>
			<template #right>
				<van-image class="language-img" @click="showLanguage()" src="img/login/nav10.png" style="width: 32px;" />
			</template>
		</van-nav-bar>


		<div class="linear-bg"></div>
		<div class="home-scroll">
			<div class="banner">
				<swiper class="banner_swiper" :options="bannerSwiperOption">
					<swiper-slide v-for="(v, key) in banners" :key="key">
						<van-image class="banner_img" round :src="v.url">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
					</swiper-slide>
				</swiper>
			</div>
			<div class="notice-bar">
				<van-notice-bar class="notice-swipe" left-icon="bullhorn-o" background="#ffffff" color="#7e5678"
					:text="this.notice" />
				<div class="linear-gradient"></div>
			</div>

			<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
				<div class="hot-recommend">
					<div class="hot-title-div">
						<div>
							<span>{{ $t('home.popular_task') }}</span>
							<i>
								{{ $t('home.popular_task_tips') }}
							</i>
						</div>
					</div>

					<div style="display: flex; width: 100%; overflow-y: auto; flex-wrap: nowrap;">
						<div v-for="(group, index) in groupedMovielist" :key="index" class="gdgride" style="margin-right: 5px;">
							<div v-for="(v, key) in group" :key="key">
								<van-image @click="toProfile(v.id)" round :src="v.img_url" style="width: 100px; height: 100px; overflow: hidden; border-radius: 8px;"></van-image>
								<span><i>{{$t('home.flyer')}}</i></span>
								<div style="position: absolute; bottom: 10px; text-align: center; color: rgb(255, 255, 255); width: 100%; font-size: 12px;">
									{{ v.xuanfei_name }}
								</div>
							</div>
						</div>
						<div style="width: 23px; padding: 20px 5px 10px; height: 145px; margin-top: 30px; background: rgb(249, 249, 249); color: rgb(153, 153, 153); font-size: 12px;">
							{{$t('home.see_more')}}
						</div>
					</div>

					<div class="hot-title-div">
						<div>
							<span>{{ $t('home.hot_recommend') }}</span>
						</div>

					</div>
					<div class="movie_list_1">
						<div class="movie-list-item" v-for="(v, key) in movielist_1" :key="key"
							@click="toProfile(v.id)">
							<div class="horizontalItem">
								<div class="imgBox">
									<div class="van-image"
										style="width: 120px; height: 120px; overflow: hidden; border-radius: 8px;">
										<img :src="v.img_url" class="van-image__img" style="object-fit: cover;">
									</div>
									<span><i>{{$t('home.high_quality')}}</i></span>
								</div>
								<div style="width: calc(100% - 140px);">
									<div class="top">
										<div>
											<van-image src="img/home/vip.png" style="width: 13px;"></van-image>
											<span>{{$t('home.certification')}}</span>
										</div>
										<div>
											<van-image src="img/home/vip.png" style="width: 13px;"></van-image>
											<span>{{$t('home.video_certification')}}</span>
										</div>
										<div> {{ v.xuanfei_name }}</div>
									</div>
									<div class="tags" v-if="v.flag">
										<!-- <template v-for="v1 in v.flag.split(',')"> -->
										<van-tag class="van-tag--large van-tag--large">{{ v.flag.split(',')[0]
											}}</van-tag>
										<van-tag class="van-tag--large van-tag--success">{{ v.flag.split(',')[1]
											}}</van-tag>
										<van-tag class="van-tag--large van-tag--danger">{{ v.flag.split(',')[2]
											}}</van-tag>
										<van-tag class="van-tag--large van-tag--warning">{{ v.flag.split(',')[3]
											}}</van-tag>
										<!-- </template> -->
									</div>

									<div style="display: flex; align-items: center;">
										<span style="font-size: 14px; color: rgb(102, 102, 102);">{{$t('home.price_space')}}：</span>
										<div class="van-rate" tabindex="0" role="radiogroup">
											<div v-for="star in totalStars" :key="star" role="radio" tabindex="0"
												:aria-setsize="totalStars" :aria-posinset="star"
												:aria-checked="star <= v.number" class="van-rate__item">
												<i class="van-icon van-icon-star van-rate__icon"
													:class="{ 'van-rate__icon--full': star <= v.number, 'van-rate__icon--empty': star > v.number }"
													style="font-size: 18px;">
												</i>
											</div>
										</div>
									</div>
									<div style="color: rgb(153, 153, 153); font-size: 12px; margin: 5px 0px;">
										{{$t('home.hight')}}: {{ v.hight }}cm
										{{$t('home.bust')}}:{{ v.bust }}
										{{$t('home.service_space')}}
										{{$t('home.tracffic')}}
									</div>
									<div class="yueta">{{$t('home.yueta')}}</div>
								</div>
							</div>

						</div>
						<div class="hot-recommend-more" @click="gotoMenu('/Video')">{{ $t('home.see_more') }}</div>
					</div>
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			totalStars: 5, // 总星星数量
			currentRating: 5, // 当前评分值，这里假设默认是5
			notice: "loading......",
			banners: [{}],
			basicData: [],
			gameitem: [{}, {}, {}, {}],
			movielist_0: [{}, {}, {}, {}],
			movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
			isLoading: false,
			movielistSwiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
			bannerSwiperOption: {
				effect: 'coverflow',
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 800,
				autoplay: true,
				coverflowEffect: {
					rotate: 50,
					stretch: 10,
					depth: 100,
					modifier: 1,
					slideShadows: true
				}
			}
		};
	},
	methods: {
		gotoMenu(router) {
			this.$router.replace(router)
		},
		toLottery(key, id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Lottery?key=' + key + "&id=" + id
				})
			} else {
				this.$router.push({
					path: '/Lottery?key=' + key + "&id=" + id
				})
			}

		},
		toProfile(id) {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Profile?id=' + id
				})
			} else {
				this.$router.push({
					path: '/Profile?id=' + id
				})
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.getBasicConfig();
				this.isLoading = false;
				this.$toast(this.$t('fresh_success'));
			}, 500);
		},
		getBasicConfig() {
			this.$http({
				method: 'get',
				url: 'sys_config'
			}).then(res => {
				this.basicData = res.data;
				console.log(this.basicData);
				this.getNotice(this.basicData); //获取公告
				this.getBanner(this.basicData); //获取banner
				// this.getGameItem(); //获取首页游戏列表
				this.getMovieList_0(this.basicData); //获取首页视频0
				this.getMovieList_1(this.basicData); //获取首页视频1
			})

		},
		getNotice(data) {
			this.notice = data.notice;
		},
		getMovieList_0(data) {
			this.movielist_0 = data.xuanfeilist
		},
		getMovieList_1(data) {
			this.movielist_1 = data.xuanfeilist
		},

		getBanner(data) {
			this.banners = data.banners;
		},
		showServiceOnline() {
			this.$router.push({ path: '/ServiceOnline' })
		},
		showLanguage() {
			this.$router.push({ path: '/Language' })
		}
	},
	computed: {
		groupedMovielist() {
		// 将 movielist_0 每两个元素为一组进行分组
		const result = [];
		for (let i = 0; i < this.movielist_0.length; i += 2) {
			result.push(this.movielist_0.slice(i, i + 2));
		}
		return result;
		},
	},
	mounted() {

	},
	created() {
		this.getBasicConfig();
	}
}
</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.van-rate__icon--full {
	/* 当星星被选中时的样式 */
	color: rgb(255, 210, 30);
	/* 星星选中时的颜色 */
}

.van-rate__icon--empty {
	/* 当星星未被选中时的样式 */
	color: #e6e2e2;
	/* 星星未选中时的颜色为白色 */
}


.newnavbar {
	background: linear-gradient(30deg, #7d76ef, #d63f8c);
}

.linear-bg {
	height: 200px;
	background: linear-gradient(270deg, #e6c3a1, #7e5678);
}

// .home-container {
// 	position: absolute !important;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	background-color: #ffffff;
// }

.linear-gradient {
	width: 100%;
	height: 2px;
	background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
	min-width: 40px;
}

.notice-swipe {
	width: calc(100% - 50px);
	height: 85px;
	font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
	transform: scale(2.5);
}

.banner {
	width: 100%;
	margin-top: -23%;
}

.banner_swiper {
	height: 100%;
	width: 100%;

	.swiper-slide {
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 620px;
		height: 300px;
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		background-color: #ffffff;
		background-position: center;
		background-size: cover;
		color: #ffffff;
	}
}

// ::v-deep .swiper-container-3d .swiper-slide-shadow-left {
// 	background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
// }

// ::v-deep .swiper-container-3d .swiper-slide-shadow-right {
// 	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
// }

.banner_img {
	border-radius: 10px;
	width: 100%;
	height: 100%;
}

// .hot-game {
// 	width: 100%;
// 	height: 100%;
// }

.hot-title-div {
	margin: 0 auto;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

// .hot-title-div>div:first-child {
// 	// width: 130px;
// 	margin-bottom: 10px;
// 	font-size: 16px;
// }

.hot-title-div div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
	font-size: 20px;
	color: #979799;
}

.hot-title-div>div:first-child span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 28px;
	// font-weight: 700;
	color: #000;
}

.hot-title-div>div:first-child i {
	background: rgb(63, 58, 91);
	padding: 4px;
	color: rgb(235, 202, 175);
	font-size: 22px;
	border-radius: 8px 0px;
}

.hot-title-div>div:nth-child(2) span {
	font-size: 25px;
	color: #979799;
}

.hot-title-div>div:first-child span:before {
	content: "";
	display: block;
	width: 5px;
	height: 30px;
	background-color: #7e5678;
	border-radius: 1px;
	margin-right: 5px;
}

// .hot-game .hot-items-div {
// 	margin-top: -3px;
// }

// .hot-game .hot-items-div span {
// 	margin-top: 10px;
// 	font-size: 28px;
// 	color: #000;
// }

.hot-recommend {
	padding: 20px;
	width: 100%;
	flex: 1;
	background-color: #fff;
}

.movie_swiper {
	.swiper-slide {
		width: 80%;
	}
}






.hot-recommend-more {
	width: 100%;
	padding-bottom: 60px;
	text-align: center;
	color: #979799;
	font-size: 30px;
}

.hot-items-div .game_item_img {
	width: 100px;
	height: 100px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
	border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 35px;
}




.horizontalItem {
	display: flex;
	border-bottom: 1px solid #eee;
	padding: 20px 0 4vw;
}

.horizontalItem .imgBox {
	position: relative;
	overflow: hidden;
	border-radius: 8px;
	margin-right: 20px;
}

.horizontalItem .imgBox>span {
	position: absolute;
	z-index: 9;
	background-color: #ebcaaf;
	color: #8d684b;
	transform: rotate(45deg);
	width: 20vw;
	height: 20vw;
	top: -11vw;
	right: -11vw;
}

.horizontalItem .imgBox>span>i {
	position: absolute;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);
	font-weight: 700;
	font-size: 4vw;
}

.horizontalItem .top {
	display: flex;
}

.horizontalItem .top>div:first-child,
.horizontalItem .top>div:nth-child(2) {
	background: linear-gradient(180deg, #e7caaf, #fff7ed);
	color: #a4826b;
	font-weight: 600;
	font-size: 3vw;
	padding: 3px;
	margin-right: 10px;
}

.horizontalItem .top>div:last-child {
	margin-left: 4vw;
	font-size: 4vw;
}

.horizontalItem .tags {
	display: flex;
	margin: 5px 0 5px;
	flex-wrap: wrap;
}

.horizontalItem .tags>.van-tag {
	padding: 5px;
	font-size: 25px;
	margin: 0 5px 5px 0;
	border-radius: 8px;
	line-height: 30px;
}

.horizontalItem .yueta {
	background: linear-gradient(90deg, #df35ad, #4f1db5);
	color: #fff;
	border-radius: 8px;
	padding: 10px 0;
	text-align: center;
	font-size: 4vw;
}

.gdgride {
    padding-right: 40px;
}
.gdgride>div {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.gdgride>div>span {
    position: absolute;
    z-index: 9;
    background-color: #ebcaaf;
    color: #8d684b;
    transform: rotate(45deg);
    width: 20vw;
    height: 20vw;
    font-size: 3vw;
    top: -11vw;
    right: -11vw;
}
.gdgride>div>span>i {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
}
</style>