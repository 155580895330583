import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './en.json';
import zhLocale from './zh.json';
import jaLocale from './ja.json';
import koLocale from './ko.json';

Vue.use(VueI18n);

const LOCALE_KEY = 'selected_language';

// 从 localStorage 中获取用户上次选择的语言，如果没有则使用默认值 'zh'
const savedLanguage = window.localStorage.getItem(LOCALE_KEY);
const defaultLanguage = savedLanguage || 'ko';// 默认语言为韩语
console.log(defaultLanguage);
const messages = {
  en: enLocale,
  zh: zhLocale,
  ja: jaLocale,
  ko: koLocale
};

const i18n = new VueI18n({
  locale: defaultLanguage, // 设置默认语言
  messages // 设置语言包
});

// 监听语言切换事件，将选择的语言保存到 localStorage
Vue.prototype.$changeLanguage = function(lang) {
  i18n.locale = lang;
  window.localStorage.setItem(LOCALE_KEY, lang);
};

export default i18n;
